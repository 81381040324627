@import '_colors';
@import './keyframes';

.socialmedia-wrapper {
  // border: 1px solid white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0rem 5rem 0rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.socialmedia-item {
  // border: 2px solid orange;
  font-size: 2rem;
  border-bottom: 3px solid transparent;
  transition: 0.3s ease;
}

.socialmedia-item:hover {
  border-bottom: 3px solid $primary-accent;
  transition: 0.3s ease;
}

.socialmedia-icon {
  color: $text-primary;
  // border: 2px solid red;
  font-size: 2rem;
  padding-right: 1rem;
}

.socialmedia-gradient {
  background: $gradient;
  background-size: 200% 200%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: Gradient 10s ease infinite;
}


@media only screen and(min-width: 320px) and(max-width: 768px) {
  .socialmedia-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0rem 0rem 2rem 0rem;
    display: flex;
    justify-content: center;
  }

  .socialmedia-item {
    // border: 1px solid orange;
    display: block;
    font-size: 1.2rem;
    border-bottom: 3px solid transparent;
    transition: 0.3s ease;
    width: 40vw;
    margin: .3rem;
    padding: .7rem;
    text-align: center;
  }

  .socialmedia-item:hover {
    border-bottom: 3px solid $primary-accent;
    transition: 0.3s ease;
  }

  .socialmedia-icon {
    color: $text-primary;
    // border: 2px solid red;
    font-size: 1.5rem;
    padding: 1rem 1.2rem;
  }

  .socialmedia-gradient {
    display: none;
    background: $gradient;
    background-size: 200% 200%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: Gradient 10s ease infinite;
  }

}