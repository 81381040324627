@import "_colors";


.legaldetails-wrapper {
  padding: 2rem;
  font-size: 0.8rem;

  .address, .contact-informaiton, .provided-by {
    padding: 2rem 0rem;
  }

  .disclaimer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }

  .provided-by {
    font-size: 0.5rem;
    .link {
      font-size: 0.5rem;
    }
  }
}