@import "_colors";
@import "_keyframes";

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $modal;
  z-index: 6;
  height: 70vh;
  width: 90vw;
  margin: auto;
  border-radius: 5px;
  display: flex;
  justify-content: space-arround;
  flex-direction: column;
  transition: opacity 0.5s;
}

.modal-fadeout {
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $modal;
  z-index: 6;
  height: 70vh;
  width: 90vw;
  margin: auto;
  border-radius: 5px;
  display: flex;
  justify-content: space-arround;
  flex-direction: column;
  pointer-events: none;
}

.close-modal {
  text-align: right;
  padding: 1rem;
}

.close-modal__animation {
  transition: .1s;
  animation: spin 2s cubic-bezier(.3, 0, .7, 1) infinite;
}

.close-modal__button {
  background: none;
  outline: none;
  border: none;
}

.modal-content {
  // border: 2px solid red;
  padding: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-message {
  text-align: center;
}

.modal-headline {
  padding: 1rem;
  font-size: 1.1rem;
}

.span-color {
  color: $primary-accent;
}

.error-message {
  font-size: 1.6rem;
  padding: 1rem;
}