@import "_colors";

*,
html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $text-primary;
  font-family: 'Roboto', sans-serif;
}

html {
  background-color: $background-dark;
  max-width: 100vw;
  overflow-x: hidden;
}

// Reset UL
ul {
  list-style-type: none;
}

// Reset Links
a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  background-color: none;
  border: none;
  outline: 0;
  -webkit-tap-highlight-color: none;
}



.main-container {
  background-color: $background-dark;
  padding: 1rem;
  margin-top: 8vh;
}

.material-icons {
  color: $text-primary;
  font-size: 5rem;
}



// Scrollbar
/* width */
::-webkit-scrollbar {
  display: none;
}