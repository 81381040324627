@import '_colors';


.side-drawer {
    width: 70%;
    max-width: 400px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $background-dark-light;
    z-index: 200;
    transform: translateX((-100%));
    transition: transform 0.3s ease-in;
}

.mobile-nav-ul {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 3rem;
}

.side-drawer.open {
    transform: translateX(0)
}
