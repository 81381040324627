@import '_colors';
@import '_keyframes';

.Navbar-wrapper {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
}

.navigation-wrapper {
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.gradient-line {
  height: 3px;
  background: $gradient;
  background-size: 200% 200%;
  animation: Gradient 3s ease infinite;
}

.logo-wrapper {
  padding: 0;
  margin: 0.2rem 2rem;
}

img {
  max-height: 8vh;
}

.desktop-nav-ul {
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}

.desktop-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.menu-button {
  display: none;
}

.link-item,
.link-item:visited,
.link-item:hover,
.link-item:active,
.link-item:focus {
  margin: 0;
  font-size: 1.4rem;
  height: 100%;
  text-decoration: none;
  color: $text-primary;
  padding: 1rem;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;

}

.link-item:hover,
.link-item:active,
.link-item:focus {
  transition: 0.6s ease-in-out;
  color: $primary-accent;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}


@media only screen and(min-width: 320px) and(max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .logo-wrapper {
    margin: 0 1rem;
  }

  .menu-button {
    display: block;
    background-color: none;
    padding: 0;
    margin: 0;
  }

  .menu-button:hover,
  .menu-button:active,
  .menu-button:focus,
  .menu-button:visited {
    background-color: transparent;
    border: none;
    outline: 0;
    text-decoration: none;
    padding: 0;
    margin: 0;
  }

  img {
    max-height: 10vh;
  }

}