$primary: #005d5d;
$primary-accent: #00e0e0;

$text-primary: #dadada;

$background-light: #dbdbdb;

$background-dark: #131313;

$background-dark-light: #1f1f1f;
$background-dark-transparent: rgba(19, 19, 19, 0.75);

$modal: rgba(0, 93, 93, .8);

$white-overlay-07: rgba(255, 255, 255, 0.07);


$gradient: linear-gradient(90deg,
  rgb(238, 119, 82),
  rgb(231, 60, 126),
  rgb(35, 166, 213),
  rgb(35, 213, 171));