@import './colors';
@import './keyframes';

.Gallery {
  // border: 2px solid red;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.Gallery-item {
  // opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  visibility: hidden;
  // transition: ease 0.1s;
}

.Gallery-item--selected {
  // opacity: 1;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.gradient {
  background: $gradient;
  background-size: 400% 400%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: Gradient 3s ease infinite;
}

.slogan {
  padding: 1rem;
  font-size: 2rem;
}