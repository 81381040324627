@import '_colors';

.projects-wrap {
    padding: 2rem;
}

.spinner-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 8vh;
}

.projects-gallery {
    // border: 1px solid white;

    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 5rem;
}

@media only screen and(min-width: 320px) and(max-width: 768px) {

    .projects-wrap {
        padding: .5rem;
    }

    .projects-gallery {
        // padding: 1rem;
        margin: 0;
        padding-top: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}