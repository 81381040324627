@import "_colors";

.footer-wrapper {
//  border: 2px solid red;
  width: 100%;
  text-align: center;

  .footer-link {
    font-weight: 100;
  }
}