@import '_colors';

.backdrop {
    background-color: $background-dark-transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}
