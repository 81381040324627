@import '_colors';

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}



@keyframes fading {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}


@keyframes spin {
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}