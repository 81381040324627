@import '_colors';

.project-item__wrap {
    position: relative;
    min-height: 500px;
    min-width: 350px;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: $background-dark-light;
    border: 1px solid $background-dark;
}

.project-item__wrap:hover {
    border: 1px solid white;
    background-color: $white-overlay-07;
}

.fontawesome-icon__style {
    color: $text-primary;
    font-size: 1.5rem;
}

.project-image__wrap {
    // border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-image {
    width: 100%;
    min-height: 220px;
    object-fit: fill;
}

.project-headline__wrap {
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem;
}

.project-desc__wrap {
    text-align: justify;
    padding: 1rem;
    font-size: 1rem;
}

.link-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    vertical-align: bottom;
}

.github-link,
.visit-link {
    display: flex;
    padding: 0.2rem;
    justify-content: space-around;
    align-items: center;
    width: 40%;
    border-radius: 5px;
    border: 2px solid transparent;
    font-size: 0.6rem;
}

.github-link:hover,
.visit-link:hover {
    border: 2px solid white;
}

.github-link {
    background-color: $background-dark;
}

.visit-link {
    background-color: $primary;
}


@media only screen and(min-width: 320px) and(max-width: 768px) {

    .projects-wrap {
        padding: 0;
        margin: 0;
    }

    .project-item__wrap {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        background-color: $background-dark-light;
        border: 1px solid $background-dark;
        width: 100%;
        text-align: center;
        overflow: hidden;
        margin-bottom: 5rem;
    }

    .fontawesome-icon__style {
        font-size: 1.5rem;
    }

    .project-image__wrap {
        // border: 1px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .project-image {
        width: 100%;
        min-height: 220px;
        object-fit: fill;
        min-height: 250px;
    }

    .project-headline__wrap {
        text-align: center;
        font-size: 1.3rem;
        padding: 1rem;
        max-width: 375px;
    }

    .project-desc__wrap {
        padding: 1rem;
    }

    .project-desc__wrap {
        text-align: justify;
        font-size: 1.1rem;
    }

    .link-container {
        padding: 1rem;
        padding-top: 3rem;
        display: flex;
        justify-content: space-between;
    }

    .github-link,
    .visit-link {
        display: flex;
        padding: 0.5rem;
        justify-content: space-around;
        align-items: center;
        width: 40%;
        font-size: 1rem;
        border-radius: 5px;
        border: 2px solid transparent;
    }

    .github-link:hover,
    .visit-link:hover {
        border: 2px solid $gradient;
    }

    .github-link {
        background-color: $background-dark;
    }

    .visit-link {
        background-color: $primary;
    }
}